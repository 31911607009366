import { useMemo, useState } from 'react';

import { ParsedProduct } from '@hultafors/shared/types';

import { toggleFavorite } from '@hultafors/eripiowear/helpers';

import { FavoritesContext, FavoritesContextValue } from './favorites-context';
export const MockFavoritesProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [favorites, setFavorites] = useState<ParsedProduct[]>([]);

  function toggleFavoriteItem(product: any, isFavorite: boolean) {
    const result = toggleFavorite({
      isFavorite,
      items: favorites || [],
      product,
    });
    setFavorites(result);
  }

  function deleteFavorites() {
    setFavorites([]);
  }

  function isProductFavorite(sku: string): boolean {
    if (favorites?.find((x) => x.sku === sku)) {
      return true;
    }
    return false;
  }

  const value: FavoritesContextValue = useMemo(() => {
    return {
      deleteFavorites,
      favorites,
      isProductFavorite,
      toggleFavoriteItem,
    };
  }, [favorites]);

  return (
    <FavoritesContext.Provider value={value}>
      {children}
    </FavoritesContext.Provider>
  );
};
